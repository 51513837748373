import * as $ from "jquery";
import "bootstrap"; // Ensure Bootstrap's JS is included
import "jquery-ui/dist/jquery-ui"; // Include jQuery UI for drag-and-drop

var MagicEmbedButton = function (context) {
    var ui = $.summernote.ui;

    // Function to normalize x.com URLs to twitter.com URLs
    function normalizeTwitterUrl(url) {
        if (url.includes('x.com')) {
            return url.replace('x.com', 'twitter.com');
        }
        return url;
    }

    // Function to detect and embed content based on the URL
    function embedContent(url, range) {
        var embedHtml = '';

        // Normalize x.com URLs to twitter.com URLs
        if (url.includes('x.com') || url.includes('twitter.com')) {
            url = normalizeTwitterUrl(url);
        }

        // YouTube
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            var videoId = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
            if (videoId && videoId[1]) {
                embedHtml = `<div class="embed-container" contenteditable="false">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId[1]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <span class="remove-embed">×</span>
                </div>`;
            }
        }
        // Twitter or X.com (using Twitter Publish iframe embed)
        else if (url.includes('twitter.com')) {
            embedHtml = `<div class="embed-container" contenteditable="false">
                <blockquote class="twitter-tweet"><a href="${url}"></a></blockquote>
                <span class="remove-embed">×</span>
            </div>`;
            // Dynamically inject the Twitter script
            setTimeout(function () {
                var script = document.createElement('script');
                script.src = 'https://platform.twitter.com/widgets.js';
                script.async = true;
                script.charset = 'utf-8';
                document.body.appendChild(script);
            }, 100);
        }
        // Facebook (using Facebook iframe embed)
        else if (url.includes('facebook.com')) {
            embedHtml = `<div class="embed-container" contenteditable="false">
                <iframe src="https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(url)}&width=500" width="500" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                <span class="remove-embed">×</span>
            </div>`;
        }
        // Spotify embed logic
        else if (url.includes('spotify.com')) {
            var embedUrl = url.replace('https://open.spotify.com/', 'https://open.spotify.com/embed/');
            embedHtml = `<div class="embed-container" contenteditable="false">
        <iframe src="${embedUrl}" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        <span class="remove-embed">×</span>
    </div>`;
        }
        // TikTok (using TikTok iframe embed)
        else if (url.includes('tiktok.com')) {
            var videoId = url.split('/').pop();
            embedHtml = `<div class="embed-container" contenteditable="false">
                <iframe src="https://www.tiktok.com/embed/${videoId}" width="325" height="575" frameborder="0" allowfullscreen></iframe>
                <span class="remove-embed">×</span>
            </div>`;
        }
        // Instagram embed logic
        else if (url.includes('instagram.com')) {
            if (url.includes('/p/')) {
                var postId = url.split('/p/')[1].split('/')[0]; // Extract the post ID
                embedHtml = `<div class="embed-container" contenteditable="false">
                                <iframe src="https://www.instagram.com/p/${postId}/embed" width="400" height="480" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
                                <span class="remove-embed">×</span>
                            </div>`;
            }
            // Check if the URL contains the /reel/ segment (for Instagram Reels)
            else if (url.includes('/reel/')) {
                var reelId = url.split('/reel/')[1].split('/')[0]; // Extract the reel ID
                embedHtml = `<div class="embed-container" contenteditable="false">
            <iframe src="https://www.instagram.com/reel/${reelId}/embed" width="400" height="480" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
            <span class="remove-embed">×</span>
        </div>`;
            } else {
                console.error('Invalid Instagram URL. Expected format: https://www.instagram.com/p/{post_id}/ or https://www.instagram.com/reel/{reel_id}/');
            }
        }
        // Google Maps embed logic (for both regular URLs and app URLs)
        else if (url.includes('google.com/maps') || url.includes('maps.app.goo.gl')) {
            // Handle regular Google Maps URL
            if (url.includes('google.com/maps')) {
                embedHtml = `<div class="embed-container" contenteditable="false">
            <iframe src="${url.replace('/maps/', '/maps/embed?pb=')}" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            <span class="remove-embed">×</span>
        </div>`;
            }
            // Handle Google Maps app URL (maps.app.goo.gl)
            else if (url.includes('maps.app.goo.gl')) {
                // Extract the map ID from the goo.gl link (using regex or split)
                var mapId = url.split('maps.app.goo.gl/')[1];
                if (mapId) {
                    var embeddedUrl = `https://www.google.com/maps?q=${mapId}&output=embed`;
                    embedHtml = `<div class="embed-container" contenteditable="false">
                <iframe src="${embeddedUrl}" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <span class="remove-embed">×</span>
            </div>`;
                } else {
                    console.error('Invalid Google Maps app URL.');
                }
            }
        }

        // Insert the embed HTML at the caret position
        if (embedHtml) {
            var node = $('<div>').html(embedHtml).get(0);
            range.insertNode(node);
            context.invoke('editor.focus');

            // Make the embed container draggable
            $(node).find('.embed-container').draggable({
                containment: 'parent',
                cursor: 'move',
                handle: 'iframe', // Allow dragging by clicking on the iframe
            });

            // Add remove functionality
            $(node).find('.remove-embed').on('click', function () {
                $(this).closest('.embed-container').remove();
            });
        }
    }

    var button = ui.button({
        contents: '<i class="fas fa-magic"></i> Magic Embed',
        tooltip: 'Embed YouTube, Twitter/X, Facebook, Spotify, TikTok, Instagram, or Maps',
        click: function () {
            var range = context.invoke('editor.createRange'); // Get the current caret position
            var selectedText = range.toString(); // Get the selected text or URL

            if (selectedText) {
                embedContent(selectedText, range);
            } else {
                // Prompt the user to enter a URL
                var url = prompt('Enter a URL from YouTube, Twitter/X, Facebook, Spotify, TikTok, Instagram, or Google Maps:');
                if (url) {
                    embedContent(url, range);
                }
            }
        }
    });

    return button.render();
}

$.fn.extend({
    insertMagicEmbed: function (context) {
        return MagicEmbedButton(context);
    }
});

$.extend($.summernote.plugins, {
    'magicEmbed': function (context) {
        context.memo('button.magicEmbed', function () {
            return MagicEmbedButton(context);
        });
    }
});
