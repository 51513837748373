import * as $ from 'jquery';
import jQuery from 'jquery';
// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

import summernote from 'script-loader!summernote/dist/summernote-bs4';
import * as DOMPurify from 'dompurify'
import './summernote/youtube'
import './summernote/twitter'
import './summernote/medialibrary'
import './summernote/map'
import './summernote/facebook'
import './summernote/related'
import './summernote/casecoverter'
import './summernote/audio'
import './summernote/video'
import './summernote/file'
import './summernote/instagram'
import './summernote/tiktok'
import './summernote/spotify'
import './summernote/custom_link'
import './summernote/wordcount'
import './summernote/file_manager'
import './summernote/magic-embed'

window.summernote   =   summernote
window.DOMPurify =DOMPurify






